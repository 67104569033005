import React from 'react';
import styled from 'styled-components';
import Layout from './Layout';
import { Link, graphql } from 'gatsby';
import { CardContainer } from './styledCard';

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;

const BlogPage = ({ data, pageContext }) => {
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? '' : '/page/' + (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();
  return (
    <Layout>
      <h3>My Blog</h3>
      <BlogsContainer>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <Link to={`/blog${node.fields.slug}`} key={node.id}>
            <CardContainer>
              <BlogList>
                <BlogTitle>{node.frontmatter.title}</BlogTitle>
                <BlogDate>{node.frontmatter.date}</BlogDate>
                <BlogExcerpt>{node.excerpt}</BlogExcerpt>
              </BlogList>
            </CardContainer>
          </Link>
        ))}
      </BlogsContainer>
      <PaginationContainer>
        {!isFirst && (
          <PaginationLink to={`/blog${prevPage}`} rel="prev">
            <SmallScreen>←</SmallScreen>
            <NormalScreen>← Previous Page</NormalScreen>
          </PaginationLink>
        )}
        <PaginationList>
          {Array.from({ length: numPages }, (_, i) => (
            <PaginationItem
              key={`pagination-number${i + 1}`}
              current={currentPage === i + 1 ? 'true' : null}
            >
              <PaginationLink
                to={i === 0 ? '/blog' : `/blog/page/${i + 1}`}
                current={currentPage === i + 1 ? 'true' : null}
              >
                {i + 1}
              </PaginationLink>
            </PaginationItem>
          ))}
        </PaginationList>
        {!isLast && (
          <PaginationLink to={`/blog/page/${nextPage}`} rel="next">
            <SmallScreen>→</SmallScreen>
            <NormalScreen>Next Page →</NormalScreen>
          </PaginationLink>
        )}
      </PaginationContainer>
    </Layout>
  );
};

const BlogsContainer = styled.ul`
  list-style: none;
  margin: 0 auto;
  max-width: 1000px;
`;

const BlogList = styled.li`
  padding: 0.2rem;
`;

const BlogTitle = styled.h4`
  color: ${props => props.theme.titleColor};
  margin-bottom: 0;
`;

const BlogDate = styled.p`
  color: ${props => props.theme.textColor};
`;

const BlogExcerpt = styled.p`
  color: ${props => props.theme.techColor};
`;

const PaginationContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const PaginationLink = styled(Link)`
  color: ${props =>
    props.current ? props.theme.highlightColor : props.theme.textColor};
  &:visited {
    outline: none;
  }
`;

const PaginationList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0 auto;
  justify-content: center;
`;

const PaginationItem = styled.li`
  min-width: 50px;
  text-align: center;
  border: ${props =>
    props.current ? '2px solid ' + props.theme.techColor : 'none'};
  border-radius: 12px;
  margin-bottom: 0;
  padding: 0.2em;
`;

const SmallScreen = styled.p`
  margin-bottom: 0;
  display: none;
  font-size: 200%;
  font-weight: 800;

  @media screen and (max-width: 560px) {
    display: flex;
  }
`;

const NormalScreen = styled.p`
  margin-bottom: 0;
  display: none;

  @media screen and (min-width: 561px) {
    display: flex;
  }
`;

export default BlogPage;
